* {
  outline: 0 !important;
  border: 0;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.test {
  background-color: rgb(94, 94, 94);
}

.fa-coins {
  font-size: 28px;
  color: rgb(216, 216, 216);
}

.fa-users {
  font-size: 28px;
  color: rgb(216, 216, 216);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root,
.App {
  height: 100%;
}

.main-style-page {
  min-height: 100vh;
  border-bottom: 0;
  height: 100%;
  background-color: rgb(243, 243, 243);
  align-items: start;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.inner-in-main-page {
  display: flex;
  background-color: white;
  width: 95%;
  border-radius: 35px;
  padding: 20px;
}


.upper-panel-in-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}


.about-page {
  font-size: 20px;
}

.btn-add {
  font-size: 15px;
  background-color: #2c8cd5;
  color: white;
  padding: 8px 13px;
  font-weight: 100;
  border-radius: 5px;
}


.split {
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
}


.controllers-input fieldset {
  height: 38px !important;
  font-size: 14px !important;
  
}

.controllers-input input {
  height: 38px !important;
  font-size: 14px !important;
}

.controllers-input div {
  height: 38px !important;
  font-size: 14px !important;
  margin-left: 25px;

}


.modal-style {
  position: absolute;
  width: 60%;
  max-width: 500px;
  border-radius: 4px 0 0 4px;
  background-color: white;
  overflow: auto;
  max-height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;
  padding-right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-style::before {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 10px;
  height: 100%;
  background-color: white;
  border-radius: 0 4px 4px 0;
}

.modal-style::after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background-color: white;
  border-radius: 0 0 4px 4px;
}

.quill {
  margin-bottom: 20px !important;
}

.quill .ql-toolbar {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.quill .ql-editor {
  height: 380px !important;
}

.quill .ql-container {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 95%;
}

.menu_close_icons .MuiSvgIcon-root {
  margin-bottom: 5px;
}

.menu_close_icons {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 100%;
}

.menu_close_icons span {
  font-size: 12px;
  font-weight: 500;
}

.fa-arrow-alt-circle-left,
.fa-arrow-alt-circle-right {
  font-size: 32px;
  margin-top: 30px;
  cursor: pointer;
  color: aliceblue;
}

.fa-arrow-alt-circle-left:hover,
.fa-arrow-alt-circle-right:hover {
  font-size: 32px;
  margin-top: 30px;
  cursor: pointer;
  color: rgb(211, 211, 211);
}


