body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor{
  height: 150px !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker-wrapper input  {
  width: 100px;
  text-align: center;
}
.react-datepicker-wrapper input {
  width: 90px;
  border: 1px solid rgb(201, 199, 199);
  border-radius: 5px;
  padding: 8px 0;
  cursor: pointer;
}


/* adaptive */
.block-pay-card {
  background-color: white !important;
  width: 30% !important;
  min-width: 250px !important;
  margin-bottom: 35px !important;
}

.constructor-card-pay {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-around !important;
  flex-direction: row !important;
  max-width: 1000px;
  width: 100%;
}

.constructor-upper-panel {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  margin-bottom: 15px !important;
}

.constructor-main {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  flex-wrap: wrap !important;
}


.constructor-buttons {
  background-color: white !important;
  width: 40% !important;
  align-items: center !important;
  padding-top: 10px !important;
}

.constructor-reload {
  padding-top: 10px !important;
  background-color: white !important;
  width: 40% !important;
}


.modal-box {
  position: absolute;
  width: 60%;
  max-width: 500px;
  border-radius: 15px;
  background-color: white;
  overflow: auto;
  max-height: 70%;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding-bottom: 20px;
}

.ellipsis-text {
  white-space: nowrap; /* Запрещаем перенос текста на новую строку */
  overflow: hidden; /* Обрезаем текст, который выходит за пределы контейнера */
  text-overflow: ellipsis; /* Добавляем тройные точки в конце обрезанного текста */
}

.analitics_rows {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.header_phone {
    width: 10%;
    min-width: 80px;
    max-width: 80px;
    background-color: rgb(44 140 213);
    display: none;
    align-items: center;
    padding: 10px;
}
 

.burger {
  display: none;
  margin-left: 15px;
  cursor: pointer;
}

.analitics_block {
  width: 19% !important;
}

.form_control_table {
  margin-top: 20px;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: baseline;
}

.form_control_table_items {
  flex-direction: row !important;
  align-items: center;
}


@media (max-width: 955px) {
  .analitics_block {
    width: 30% !important;
  }
}

@media (max-width: 888px) {
  .header_adaptive {
    display: none !important;
  }
  .header_phone {
    display: flex;
  }
  .burger {
    display: block;
  }
  .header_title {
    font-size: 24px !important;
  }
  .block-pay-card {
    width: 100% !important;
  }
  .constructor-main div {
    width: 100% !important;
    min-width: 0px !important;
  }
  
  .save-constructor {
    width: 100% !important;
    min-width: 0px !important;
  }
}


@media (max-width: 690px) {
  .form_control_table {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 25px 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .form_control_table div:nth-child(1) {
    margin-bottom: 2px;
  }


  .form_control_table_items {
    flex-direction: column !important;
    width: 100%;
  }
  .form_control_table_items div {
    margin: 1px 0 !important;
  }

  .controllers-input {
    width: 100%;
  }

}


@media (max-width: 579px) {
  .analitics_block {
    width: 70% !important;
  }
  .modal-box {
    width: 90%;
    max-width: none;
  }
  .constructor-upper-panel {
    flex-direction: column !important;
    align-items: center !important;
  }
  .constructor-buttons {
    width: 100% !important;
  }
  .constructor-reload {
    width: 100% !important;
  }
}

@media (max-width: 370px) {
  
  .controllers-input fieldset {
    height: 38px !important;
    font-size: 14px !important;
    width: 100%;
  }
  
  .controllers-input input {
    height: 38px !important;
    font-size: 14px !important;
    width: 100%;
  }
  
  .controllers-input div {
    height: 38px !important;
    font-size: 14px !important;
      width: 100%;
  }

}

